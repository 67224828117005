<template>
  <div class="myCalendar">
    <div class="head">
      <div class="showPopup" @click="handshowDtPicker">
        <svg-icon icon-class="interview_rili"></svg-icon>
        {{ yymmdd }}
        <span>
          <svg-icon icon-class="dropdown"></svg-icon>
        </span>
      </div>
    </div>
    <vue-hash-calendar
      :visible="show"
      :pickerType="'date'"
      format="YY/MM/DD"
      :defaultDatetime="selectDate"
      :showTodayButton="false"
      :isShowWeekView="true"
      :isShowAction="false"
      :markDate="markDate"
      :min-date="nminDate"
      :max-date="nmaxDate"
      @change="getdate"
    >
      <div slot="day" slot-scope="scope">
        <div class="day">{{ scope.date.day }}</div>
        <div class="lunar">{{ lunar(scope) }}</div>
      </div>
    </vue-hash-calendar>
    <!--       
      @confirm="getdate" // 确定按钮触发
      @click="getdate"  // 点击日期触发
    -->
    <van-popup v-model="showDtPicker" round position="bottom">
      <van-datetime-picker
        v-model="selectDate2"
        type="date"
        :min-date="nminDate"
        :max-date="nmaxDate"
        :title="'选择年月日'"
        @confirm="onDtConfirm"
        @cancel="showDtPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import { getLunar } from "chinese-lunar-calendar";
export default {
  name: "myCalendar",
  props: {
    // 最小可选日期
    minDate: {
      type: Date,
      default: null,
    },
    // 最大可选日期
    maxDate: {
      type: Date,
      default: null,
    },
    // 传入日期
    setDate: {
      type: Date,
      default: () => {
        return new Date();
      },
    },
    // 显示
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectDate: new Date(), // 选中时间
      selectDate2: new Date(), // 选中时间
      markDate: [
        { color: "red", type: "circle", date: ["2021/04/01"] },
        { color: "blue", type: "dot", date: ["2021/04/02"] },
        "2021/04/03",
      ],
      showDtPicker: false,
      nminDate: this.minDate || this.dd("min"),
      nmaxDate: this.maxDate || this.dd("max"),
      yymmdd: parseTime(this.selectDate, "{y}/{m}/{d}"),
    };
  },
  watch: {
    setDate: {
      handler(n) {
        if (n) {
          this.selectDate = n;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 日期变更（最终）
    getdate(d) {
      this.yymmdd = d;
      this.$emit("outDate", d);
    },
    // 显示下拉框
    handshowDtPicker() {
      this.selectDate2 = this.selectDate;
      this.showDtPicker = true;
    },
    // 下拉框选择日期
    onDtConfirm(v) {
      this.selectDate = v;
      this.getdate(parseTime(v, "{y}/{m}/{d}"));
      this.showDtPicker = false;
    },
    //日期控件的最大日期/最小日期
    dd(m) {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const day = d.getDate();
      if (m === "min") {
        return new Date(year - 100, month, day);
      } else {
        return new Date(year + 30, month, day);
      }
    },
    // 农历+节假日
    lunar(scope) {
      const getLunarDay = getLunar(
        scope.date.year,
        scope.date.month + 1,
        scope.date.day
      );
      const v =
        scope.date.month == 0 && scope.date.day == 1
          ? "元旦"
          : scope.date.month == 4 && scope.date.day == 1
          ? "劳动节"
          : scope.date.month == 9 && scope.date.day == 1
          ? "国庆"
          : scope.date.month == 2 && scope.date.day == 8
          ? "妇女节"
          : getLunarDay.dateStr == "正月初一"
          ? "春节"
          : getLunarDay.dateStr == "正月十五"
          ? "元宵"
          : getLunarDay.dateStr == "五月初五"
          ? "端午"
          : getLunarDay.dateStr == "八月十五"
          ? "中秋"
          : getLunarDay.solarTerm
          ? getLunarDay.solarTerm
          : getLunarDay.dateStr.indexOf("初一") > -1
          ? getLunarDay.dateStr.substring(0, 2)
          : getLunarDay.dateStr.substring(2);
      return v;
    },
  },
};
</script>

<style lang="less" scoped>
.myCalendar {
  .head {
    font-family: Source Han Sans CN;
    display: flex;
    align-items: center;
    font-size: 32px;
    line-height: 100%;
    background: #fff;
    height: 2em;
    .showPopup {
      color: #333;
      span {
        color: #999;
        font-size: 24px;
        margin-left: 10px;
        .svg-icon {
          margin: 0;
        }
      }
      .svg-icon {
        margin: 0 30px;
      }
    }
  }
  /deep/.hash-calendar {
    .calendar_content {
      .calendar_body {
        .calendar_week {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #333;
        }
      }
    }
  }
  /deep/ .calendar_day {
    div {
      text-align: center;
    }
    .lunar {
      font-size: 24px;
    }
  }
}
</style>